.carousel-words {
    flex-direction: column;
    text-align: center;
}

.block.homepage-block--button {
    text-align: center;
}

.homepage-block--title {
    padding: $spacer-large $spacer-large 0 $spacer-large;
}

header {
    #menu-top {
        // TODO : Sticky bug position sticky, remove left & right
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: $zindex-navigation;
    }

    #menu-top {
        background: $primary-500;
        box-shadow: $shadow-1;
        height: $height-menu-top;

        a {
            text-decoration: none;
        }
    }

    // TODO : Sticky bug remove
    &:after {
        display: block;
        content: "";
        height: $height-menu-top;
    }
}

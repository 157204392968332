.block {
    margin: $spacer-medium 0;

    &.block--m {
        margin: $spacer-large 0;
    }

    &.block--l {
        margin: $spacer-extra-large 0;
    }

    &.block--xl {
        margin: $spacer-ultra-large 0;
    }

    &.block--h1-main {
        margin: $spacer-large-plus 0 0 0;

        &:nth-last-of-type(1) {
            margin-bottom: $spacer-large-plus;
        }

        h1 {
            margin-bottom: $spacer-large;
        }
    }
}

.spacer + .spacer--tiny {
    margin-top: $spacer-tiny;
}

.spacer + .spacer--small {
    margin-top: $spacer-small;
}

.spacer + .spacer--medium {
    margin-top: $spacer-medium;
}

.spacer + .spacer--large {
    margin-top: $spacer-large;
}

.spacer + .spacer--large-plus {
    margin-top: $spacer-large-plus;
}

.spacer + .spacer--extra-large {
    margin-top: $spacer-extra-large;
}

.flex-card {
    display: grid;
    grid-gap: $spacer-medium;
    grid-auto-flow: row;
}

.card {
    display: block;
    background-color: $white;
    padding: $spacer-medium;
    border: $border-2;

    .card-title {
        font-family: $font-family-heading;
        @include font-size(h6);

        > .btn {
            width: 100%;
        }
    }

    .card-sub-title {
        font-family: $font-family-heading;
        @include font-size(h6);
        margin-top: $spacer-medium;
        text-align: center;
    }

    .card-text {
        text-align: justify;
        hyphens: auto;
        margin: $spacer-medium $spacer-small;
    }
}
/* ----------------------------- */
/* ==Base (basic styles)         */
/* ----------------------------- */
/* switching to border-box model for all elements */
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  /* avoid min-width: auto on flex and grid children */
  min-width: 0;
  min-height: 0;
}

html {
  box-sizing: border-box;
  /* set base font-size to equiv "10px", which is adapted to rem unit */
  font-size: 62.5%;
  /* IE9-IE11 math fixing. See http://bit.ly/1g4X0bX */
  font-size: 0.625em;
}

body {
  margin: 0;
  font-size: 1.6rem;
  background-color: #f3f2f2;
  color: hsl(4, 5%, 28%);
  font-family: Mulish, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 300;
  line-height: 1.5;
}
@media (min-width: 576px) {
  body {
    font-size: 1.8rem;
  }
}

/* ----------------------------- */
/* ==Global Micro Layout         */
/* ----------------------------- */
/* module, gains superpower "BFC" Block Formating Context */
.mod,
.bfc {
  overflow: hidden;
}

/* blocks that needs to be placed under floats */
.clear {
  clear: both;
}

/* blocks that must contain floats */
.clearfix::after {
  content: "";
  display: table;
  clear: both;
  border-collapse: collapse;
}

/* simple blocks alignment */
.left {
  margin-right: auto;
}

.right {
  margin-left: auto;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

/* text and contents alignment */
.txtleft {
  text-align: left;
}

.txtright {
  text-align: right;
}

.txtcenter {
  text-align: center;
}

/* floating elements */
.fl {
  float: left;
}

img.fl {
  margin-right: 1rem;
}

.fr {
  float: right;
}

img.fr {
  margin-left: 1rem;
}

img.fl,
img.fr {
  margin-bottom: 0.5rem;
}

/* inline-block */
.inbl {
  display: inline-block;
  vertical-align: top;
}

/* flexbox layout
http://www.alsacreations.com/tuto/lire/1493-css3-flexbox-layout-module.html
*/
.flex-container, .flex-container--column-reverse,
.flex-column-reverse, .flex-container--row-reverse,
.flex-row-reverse, .flex-container--column,
.flex-column, .flex-container--row,
.flex-row,
.d-flex {
  display: flex;
  flex-wrap: wrap;
}

.flex-container--row,
.flex-row {
  flex-direction: row;
}

.flex-container--column,
.flex-column {
  flex-direction: column;
}

.flex-container--row-reverse,
.flex-row-reverse {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.flex-container--column-reverse,
.flex-column-reverse {
  flex-direction: column-reverse;
  justify-content: flex-end;
}

.flex-item-fluid,
.item-fluid {
  flex: 1 1 0%;
}

.flex-item-first,
.item-first {
  order: -1;
}

.flex-item-medium,
.item-medium {
  order: 0;
}

.flex-item-last,
.item-last {
  order: 1;
}

.flex-item-center,
.item-center,
.mr-auto {
  margin: auto;
}

.app-container {
  overflow-x: hidden;
}
.app-container .app-container-child {
  background-image: url("../../../images/frise.webp");
}
.app-container .app-container-child--overlay {
  background-color: #f3f2f2;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1001;
}
.app-container .app-container-child .app-container-content {
  min-height: calc(100vh - (55px + 6rem + 2px));
}
.app-container .app-container-child .app-container-content--fixed {
  height: calc(100vh - (6rem + 2px));
}
.app-container .app-container-child .app-container-content #sticky-bottom {
  position: fixed;
  height: 11rem;
  bottom: calc(6rem + 2px);
  left: 0;
  right: 0;
  display: flex;
  justify-content: end;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.app-container .app-container-child .app-container-content #sticky-bottom-placeholder {
  display: block;
  content: "";
  height: 11rem;
}
.app-container .app-container-child #menu-app:after {
  display: block;
  content: "";
  height: calc(6rem + 2px);
}

.block {
  margin: 2rem 0;
}
.block.block--m {
  margin: 4rem 0;
}
.block.block--l {
  margin: 8rem 0;
}
.block.block--xl {
  margin: 16rem 0;
}
.block.block--h1-main {
  margin: 6rem 0 0 0;
}
.block.block--h1-main:nth-last-of-type(1) {
  margin-bottom: 6rem;
}
.block.block--h1-main h1 {
  margin-bottom: 4rem;
}

.spacer + .spacer--tiny {
  margin-top: 0.5rem;
}

.spacer + .spacer--small {
  margin-top: 1rem;
}

.spacer + .spacer--medium {
  margin-top: 2rem;
}

.spacer + .spacer--large {
  margin-top: 4rem;
}

.spacer + .spacer--large-plus {
  margin-top: 6rem;
}

.spacer + .spacer--extra-large {
  margin-top: 8rem;
}

#breadcrumb {
  font-size: 1.44rem;
  border-bottom: 1px solid hsl(4, 5%, 90%);
  background-color: #f8f6f6;
  color: hsl(199, 69%, 37%);
  line-height: 2em;
}
@media (min-width: 576px) {
  #breadcrumb {
    font-size: 1.62rem;
  }
}
#breadcrumb ol {
  list-style-type: none;
  display: flex;
  column-gap: 0.5rem;
  margin: 0;
  padding: 0 1rem;
}
#breadcrumb ol > li {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
#breadcrumb ol > li.no-shrink {
  flex-shrink: 0;
}

.flex-card {
  display: grid;
  grid-gap: 2rem;
  grid-auto-flow: row;
}

.card {
  display: block;
  background-color: #fafafa;
  padding: 2rem;
  border: 2px solid #dedede;
}
.card .card-title {
  font-family: Karla, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.76rem;
}
@media (min-width: 576px) {
  .card .card-title {
    font-size: 2.16rem;
  }
}
.card .card-title > .btn {
  width: 100%;
}
.card .card-sub-title {
  font-family: Karla, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.76rem;
  margin-top: 2rem;
  text-align: center;
}
@media (min-width: 576px) {
  .card .card-sub-title {
    font-size: 2.16rem;
  }
}
.card .card-text {
  text-align: justify;
  hyphens: auto;
  margin: 2rem 1rem;
}

.event-tags {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  column-gap: 1rem;
  row-gap: 0.5rem;
}
.event-tags > .event-tags--muted {
  color: hsl(7, 5%, 63%);
}

.event-result-container {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}

.event-result {
  background-color: #ffffff;
  border: 2px #fafafa solid;
  border-radius: 0.7rem;
  box-shadow: 0 3px 6px hsla(4, 10%, 5%, 0.15), 0 2px 4px hsla(4, 10%, 5%, 0.12);
  display: block;
  position: relative;
  overflow: hidden;
  padding: 2rem 2rem 6rem;
  text-decoration: none;
}
.event-result .event-result-actions {
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
}
.event-result .event-result-flex {
  display: flex;
  align-items: center;
  column-gap: 2rem;
}
.event-result .event-bottom {
  font-size: 1.44rem;
  position: absolute;
  bottom: 1.5rem;
  right: 2rem;
}
@media (min-width: 576px) {
  .event-result .event-bottom {
    font-size: 1.62rem;
  }
}
.event-result .event-bottom > .event-tags {
  justify-content: flex-end;
}

.event-result.event-result-small {
  padding: 2rem;
  height: calc(100px + 4rem + 4px);
}
.event-result.event-result-small .event-bottom {
  bottom: 0.5rem;
  right: 1rem;
  height: 1.5em;
  overflow: hidden;
}

.event-info-container {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 2rem;
  margin-top: 2rem;
  align-items: center;
}
.event-info-container .event-address {
  margin-top: 2rem;
  display: flex;
}
.event-info-container .event-info {
  font-size: 1.44rem;
  display: flex;
  align-items: center;
}
@media (min-width: 576px) {
  .event-info-container .event-info {
    font-size: 1.62rem;
  }
}
.event-info-container .event-info .faws {
  text-align: center;
  width: 1.1em;
  margin-right: 2rem;
}

.event-result--events-title {
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
  flex-wrap: wrap;
  gap: 2rem;
}
.event-result--events-title > .row-action {
  flex-grow: 1;
}

.faws {
  display: inline-block;
}
.faws svg {
  display: block;
  height: 1em;
  width: 1em;
}
.faws svg path {
  fill: currentColor;
}
.faws.hourglass {
  animation: rotateFrame 2s infinite cubic-bezier(0.79, 0.14, 0.15, 0.86);
}
.faws.spinner {
  animation: rotateFrame 1s infinite linear;
}

@keyframes rotateFrame {
  0% {
    transform: none;
  }
  100% {
    transform: rotate(360deg);
  }
}
#footer-lazy {
  display: none;
}

header #menu-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}
header #menu-top {
  background: hsl(199, 69%, 37%);
  box-shadow: 0 1px 3px hsla(4, 10%, 5%, 0.12), 0 1px 3px hsla(4, 10%, 5%, 0.24);
  height: 55px;
}
header #menu-top a {
  text-decoration: none;
}
header:after {
  display: block;
  content: "";
  height: 55px;
}

/* Headings */
.title-big .title-sub, .title-big .title, h1, h2, h3, h4, h5, h6, .h1-like, .h2-like, .h3-like, .h4-like, .h5-like, .h6-like {
  font-family: Karla, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  margin: 0;
  font-weight: 720;
  letter-spacing: 0.03em;
}
.title-big .light.title-sub, .title-big .light.title, h1.light, h2.light, h3.light, h4.light, h5.light, h6.light, .light.h1-like, .light.h2-like, .light.h3-like, .light.h4-like, .light.h5-like, .light.h6-like {
  font-weight: 200;
}

h1, .h1-like {
  font-size: 3.04rem;
}
@media (min-width: 576px) {
  h1, .h1-like {
    font-size: 3.6rem;
  }
}
h1.title-space, .h1-like.title-space {
  letter-spacing: 0.2em;
}

h2, .h2-like {
  font-size: 2.56rem;
}
@media (min-width: 576px) {
  h2, .h2-like {
    font-size: 3.24rem;
  }
}
h2.title-space, .h2-like.title-space {
  letter-spacing: 0.15em;
}

h3, .h3-like {
  font-size: 2.24rem;
}
@media (min-width: 576px) {
  h3, .h3-like {
    font-size: 2.88rem;
  }
}
h3.title-space, .h3-like.title-space {
  letter-spacing: 0.1em;
}

h4, .h4-like {
  font-size: 2.08rem;
}
@media (min-width: 576px) {
  h4, .h4-like {
    font-size: 2.52rem;
  }
}

h5, .h5-like {
  font-size: 1.92rem;
}
@media (min-width: 576px) {
  h5, .h5-like {
    font-size: 2.34rem;
  }
}

h6, .h6-like {
  font-size: 1.76rem;
}
@media (min-width: 576px) {
  h6, .h6-like {
    font-size: 2.16rem;
  }
}

.title-big {
  font-weight: 200;
  text-align: center;
}
.title-big .title {
  font-size: 2.56rem;
  letter-spacing: 0.5rem;
}
@media (min-width: 576px) {
  .title-big .title {
    font-size: 3.24rem;
  }
}
.title-big .title-sub {
  font-size: 1.92rem;
  margin-top: 1rem;
}
@media (min-width: 576px) {
  .title-big .title-sub {
    font-size: 2.34rem;
  }
}

.carousel-words {
  flex-direction: column;
  text-align: center;
}

.block.homepage-block--button {
  text-align: center;
}

.homepage-block--title {
  padding: 4rem 4rem 0 4rem;
}

.btn--homepage-search {
  font-size: 1.92rem;
  margin-top: 4rem;
  font-weight: 300;
}
@media (min-width: 576px) {
  .btn--homepage-search {
    font-size: 2.34rem;
  }
}

.notification-container {
  position: relative;
}
.notification-container .notification {
  font-size: 1.44rem;
  position: absolute;
  top: -0.4em;
  right: -0.4em;
  background-color: hsl(2, 78%, 51%);
  height: 1.2em;
  min-width: 1.2em;
  border-radius: 50%;
  color: #f8f6f6;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 576px) {
  .notification-container .notification {
    font-size: 1.62rem;
  }
}

.center-left {
  text-align: center;
}

.fog {
  background: radial-gradient(rgba(243, 242, 242, 0.4) 50%, rgba(255, 255, 255, 0));
  background-size: 1000px 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.justify {
  text-align: justify;
  text-align-last: left;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

strong {
  font-weight: 600;
}

a.a-disabled {
  color: hsl(4, 5%, 28%);
}
a.a-disabled:hover {
  color: hsl(4, 5%, 28%);
}

body[show-status=on] {
  overflow: hidden;
}

.sticky {
  position: sticky;
}
.sticky:not(.sticky--bottom) {
  top: 55px;
}
.sticky.sticky--bottom {
  bottom: calc(6rem + 2px);
}

.relative {
  position: relative;
}

@media (max-width: 575px) {
  .flex-row.flex-align-middle {
    align-items: center;
    height: 100%;
  }
}
.map-desktop {
  display: none;
}

[menu-shrink] {
  display: flex;
}
[menu-shrink][menu-shrink=menu] {
  text-align: center;
}
[menu-shrink] .menu-shrink--button {
  width: 5rem;
}
[menu-shrink][menu-shrink-open=false] [menu-shrink-show-if=open] {
  display: none;
}
[menu-shrink] [menu-shrink-content] {
  padding: 1rem 2rem 1rem 2rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
}
[menu-shrink] [menu-shrink-item] {
  display: flex;
  flex-direction: row;
}
[menu-shrink] [menu-shrink-item] > a {
  flex-grow: 1;
}
[menu-shrink] [menu-shrink-item] > ul {
  margin: 0;
}
[menu-shrink] [menu-shrink-item=current] > a {
  pointer-events: none;
  text-decoration: none;
}
[menu-shrink] [menu-shrink-item=item], [menu-shrink] [menu-shrink-item=separator], [menu-shrink] [menu-shrink-item=ul] ul > ul {
  display: none;
}

body.menu-open {
  overflow: hidden;
}

.search .multiselect--container {
  height: 4rem;
  overflow: visible;
}

[menu-slide-container] {
  position: relative;
}
[menu-slide-container][menu-slide-state=hidden] {
  display: none;
}

[menu-slide][menu-slide-open=true] {
  font-family: Karla, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}
[menu-slide][menu-slide-open=true] > [menu-slide-background] {
  pointer-events: auto;
  opacity: 1;
}
[menu-slide][menu-slide-open=true] .multiselect.is-active::after {
  content: "";
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
[menu-slide] > [menu-slide-background] {
  background-color: hsla(199, 20%, 93%, 0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  z-index: 1500;
  transition-property: opacity;
  transition: ease 250ms;
}
[menu-slide] > [menu-slide-content] {
  background-color: #fff;
  top: 0;
  bottom: 0;
  height: 100%;
  max-width: 80%;
  width: 20em;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  transition-property: left, top, right, bottom;
  transition: ease 250ms;
  z-index: 1501;
}
[menu-slide][menu-slide-position=left] > [menu-slide-content] {
  left: 0;
}
[menu-slide][menu-slide-position=left][menu-slide-open=false] [menu-slide-content] {
  left: -100%;
}
[menu-slide][menu-slide-position=right] > [menu-slide-content] {
  right: 0;
}
[menu-slide][menu-slide-position=right][menu-slide-open=false] [menu-slide-content] {
  right: -100%;
}

.page-header--container {
  margin-top: 2rem;
}

.page-header {
  border: 3px solid #dedede;
  border-radius: 0.3rem;
  padding: 1rem;
  background: #fafafa;
  display: grid;
}
.page-header .page-header-image {
  margin: auto;
  height: 100px;
  width: 100px;
}
.page-header .page-header-image .image-scale {
  border-radius: 0.7rem;
  box-shadow: 0 1px 3px hsla(4, 10%, 5%, 0.12), 0 1px 3px hsla(4, 10%, 5%, 0.24);
}
.page-header .page-header-title {
  text-align: center;
  margin-top: 3rem;
  font-size: 2.24rem;
}
@media (min-width: 576px) {
  .page-header .page-header-title {
    font-size: 2.88rem;
  }
}
.page-header .page-header-subtitle {
  text-align: center;
  margin-top: 1rem;
  font-size: 2.08rem;
}
@media (min-width: 576px) {
  .page-header .page-header-subtitle {
    font-size: 2.52rem;
  }
}
.page-header .page-header-short-desc {
  margin-top: 1rem;
  text-align: center;
}
@media (min-width: 400px) {
  .page-header .page-header-short-desc {
    margin-top: 1.5rem;
  }
}
.page-header .page-header-address {
  font-size: 1.44rem;
  text-align: center;
}
@media (min-width: 576px) {
  .page-header .page-header-address {
    font-size: 1.62rem;
  }
}
.page-header .page-header-description {
  font-size: 1.44rem;
  max-width: 650px;
  margin: 4rem auto 0;
  text-align: justify;
  text-align-last: center;
}
@media (min-width: 576px) {
  .page-header .page-header-description {
    font-size: 1.62rem;
  }
}
@media (max-width: 399px) {
  .page-header .page-header-description {
    margin: 2rem auto;
  }
}
.page-header .page-header-website {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 1.5rem;
}
.page-header .page-header-action {
  margin-top: 1.5rem;
}
.page-header .page-header-action.row-action--group {
  align-items: end;
}
.page-header .page-header-action > [vue=dropdown] {
  flex-shrink: 0;
}

.route-list {
  font-size: 1.44rem;
  margin-top: 2rem;
}
@media (min-width: 576px) {
  .route-list {
    font-size: 1.62rem;
  }
}
.route-list [menu-shrink] {
  box-shadow: none;
  border: 1px solid #dedede;
}
.route-list [menu-shrink] [menu-shrink-item] > ul {
  padding-left: 0;
}
.route-list [menu-shrink] [menu-shrink-item] > ul > li {
  list-style-type: none;
  font-weight: 500;
}
.route-list [menu-shrink] [menu-shrink-item] > ul > ul {
  margin-top: 0.5rem;
}
.route-list [menu-shrink] [menu-shrink-item] > ul > ul > li {
  list-style-type: initial;
}

.row-action {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: end;
  align-items: center;
}
.row-action--group {
  flex-wrap: nowrap;
}

[show-index=default][show-status=on] [show-if][show-index=default]:not([show-if=on]) {
  display: none;
}
[show-index=default][show-status=on] [show-if][show-index=default]:not([show-if=on]).form-row + .form-row {
  margin-top: 0;
}

[show-index=default][show-status=off] [show-if][show-index=default]:not([show-if=off]) {
  display: none;
}
[show-index=default][show-status=off] [show-if][show-index=default]:not([show-if=off]).form-row + .form-row {
  margin-top: 0;
}

[show-index=default][show-status=place] [show-if][show-index=default]:not([show-if=place]) {
  display: none;
}
[show-index=default][show-status=place] [show-if][show-index=default]:not([show-if=place]).form-row + .form-row {
  margin-top: 0;
}

[show-index=default][show-status=location] [show-if][show-index=default]:not([show-if=location]) {
  display: none;
}
[show-index=default][show-status=location] [show-if][show-index=default]:not([show-if=location]).form-row + .form-row {
  margin-top: 0;
}

[show-index=default][show-status=none] [show-if][show-index=default]:not([show-if=none]) {
  display: none;
}
[show-index=default][show-status=none] [show-if][show-index=default]:not([show-if=none]).form-row + .form-row {
  margin-top: 0;
}

[show-index=closing_doors][show-status="0"] [show-if][show-index=closing_doors]:not([show-if="0"]) {
  display: none;
}
[show-index=closing_doors][show-status="0"] [show-if][show-index=closing_doors]:not([show-if="0"]).form-row + .form-row {
  margin-top: 0;
}

[show-index=closing_doors][show-status="1"] [show-if][show-index=closing_doors]:not([show-if="1"]) {
  display: none;
}
[show-index=closing_doors][show-status="1"] [show-if][show-index=closing_doors]:not([show-if="1"]).form-row + .form-row {
  margin-top: 0;
}

[show-index=closing_doors][show-status="2"] [show-if][show-index=closing_doors]:not([show-if="2"]) {
  display: none;
}
[show-index=closing_doors][show-status="2"] [show-if][show-index=closing_doors]:not([show-if="2"]).form-row + .form-row {
  margin-top: 0;
}

[show-index=show_pricing][show-status="0"] [show-if][show-index=show_pricing]:not([show-if="0"]) {
  display: none;
}
[show-index=show_pricing][show-status="0"] [show-if][show-index=show_pricing]:not([show-if="0"]).form-row + .form-row {
  margin-top: 0;
}

[show-index=show_pricing][show-status="1"] [show-if][show-index=show_pricing]:not([show-if="1"]) {
  display: none;
}
[show-index=show_pricing][show-status="1"] [show-if][show-index=show_pricing]:not([show-if="1"]).form-row + .form-row {
  margin-top: 0;
}

[show-index=show_pricing][show-status="2"] [show-if][show-index=show_pricing]:not([show-if="2"]) {
  display: none;
}
[show-index=show_pricing][show-status="2"] [show-if][show-index=show_pricing]:not([show-if="2"]).form-row + .form-row {
  margin-top: 0;
}

[show-index=price_option][show-status=name] [show-if][show-index=price_option]:not([show-if=name]) {
  display: none;
}
[show-index=price_option][show-status=name] [show-if][show-index=price_option]:not([show-if=name]).form-row + .form-row {
  margin-top: 0;
}

[show-index=price_option][show-status=age] [show-if][show-index=price_option]:not([show-if=age]) {
  display: none;
}
[show-index=price_option][show-status=age] [show-if][show-index=price_option]:not([show-if=age]).form-row + .form-row {
  margin-top: 0;
}

[show-index=price_option][show-status=off] [show-if][show-index=price_option]:not([show-if=off]) {
  display: none;
}
[show-index=price_option][show-status=off] [show-if][show-index=price_option]:not([show-if=off]).form-row + .form-row {
  margin-top: 0;
}

[show-index=price_age][show-status=min] [show-if][show-index=price_age]:not([show-if=min]) {
  display: none;
}
[show-index=price_age][show-status=min] [show-if][show-index=price_age]:not([show-if=min]).form-row + .form-row {
  margin-top: 0;
}

[show-index=price_age][show-status=max] [show-if][show-index=price_age]:not([show-if=max]) {
  display: none;
}
[show-index=price_age][show-status=max] [show-if][show-index=price_age]:not([show-if=max]).form-row + .form-row {
  margin-top: 0;
}

[show-index=price_age][show-status=off] [show-if][show-index=price_age]:not([show-if=off]) {
  display: none;
}
[show-index=price_age][show-status=off] [show-if][show-index=price_age]:not([show-if=off]).form-row + .form-row {
  margin-top: 0;
}

[show-index=menu-plus][show-status=on] [show-if][show-index=menu-plus]:not([show-if=on]) {
  display: none;
}
[show-index=menu-plus][show-status=on] [show-if][show-index=menu-plus]:not([show-if=on]).form-row + .form-row {
  margin-top: 0;
}

[show-index=menu-plus][show-status=off] [show-if][show-index=menu-plus]:not([show-if=off]) {
  display: none;
}
[show-index=menu-plus][show-status=off] [show-if][show-index=menu-plus]:not([show-if=off]).form-row + .form-row {
  margin-top: 0;
}

[show-index=place_category][show-status=on] [show-if][show-index=place_category]:not([show-if=on]) {
  display: none;
}
[show-index=place_category][show-status=on] [show-if][show-index=place_category]:not([show-if=on]).form-row + .form-row {
  margin-top: 0;
}

[show-index=place_category][show-status=off] [show-if][show-index=place_category]:not([show-if=off]) {
  display: none;
}
[show-index=place_category][show-status=off] [show-if][show-index=place_category]:not([show-if=off]).form-row + .form-row {
  margin-top: 0;
}

[show-index=upload][show-status=on] [show-if][show-index=upload]:not([show-if=on]) {
  display: none;
}
[show-index=upload][show-status=on] [show-if][show-index=upload]:not([show-if=on]).form-row + .form-row {
  margin-top: 0;
}

[show-index=upload][show-status=off] [show-if][show-index=upload]:not([show-if=off]) {
  display: none;
}
[show-index=upload][show-status=off] [show-if][show-index=upload]:not([show-if=off]).form-row + .form-row {
  margin-top: 0;
}

[show-index=image][show-status=on] [show-if][show-index=image]:not([show-if=on]) {
  display: none;
}
[show-index=image][show-status=on] [show-if][show-index=image]:not([show-if=on]).form-row + .form-row {
  margin-top: 0;
}

[show-index=image][show-status=off] [show-if][show-index=image]:not([show-if=off]) {
  display: none;
}
[show-index=image][show-status=off] [show-if][show-index=image]:not([show-if=off]).form-row + .form-row {
  margin-top: 0;
}

[show-index=read-more][show-status=on] [show-if][show-index=read-more]:not([show-if=on]) {
  display: none;
}
[show-index=read-more][show-status=on] [show-if][show-index=read-more]:not([show-if=on]).form-row + .form-row {
  margin-top: 0;
}

[show-index=read-more][show-status=off] [show-if][show-index=read-more]:not([show-if=off]) {
  display: none;
}
[show-index=read-more][show-status=off] [show-if][show-index=read-more]:not([show-if=off]).form-row + .form-row {
  margin-top: 0;
}

[show-index=form_disabled][show-status=on] [show-if][show-index=form_disabled]:not([show-if=on]) {
  display: none;
}
[show-index=form_disabled][show-status=on] [show-if][show-index=form_disabled]:not([show-if=on]).form-row + .form-row {
  margin-top: 0;
}

[show-index=form_disabled][show-status=off] [show-if][show-index=form_disabled]:not([show-if=off]) {
  display: none;
}
[show-index=form_disabled][show-status=off] [show-if][show-index=form_disabled]:not([show-if=off]).form-row + .form-row {
  margin-top: 0;
}

[show-auto] {
  cursor: pointer;
}

[vue=dropdown] {
  width: 5rem;
}

.wrapper-small {
  max-width: 20rem;
}

.wrapper-small-plus {
  max-width: 35rem;
}

.wrapper-medium {
  max-width: 50rem;
}

.wrapper-medium-plus {
  max-width: 650px;
}

.wrap-it-all, .block .sub-block, .wrapper-large--margin, .wrapper-large, #breadcrumb > ol, #sticky-bottom {
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
}
.wrap-it-all {
  padding-top: 10px;
  padding-bottom: 10px;
}
.block .sub-block, .wrapper-large--margin, #sticky-bottom, .wrap-it-all {
  padding-left: 10px;
  padding-right: 10px;
}

.margin-auto {
  margin-right: auto;
  margin-left: auto;
}

.fill {
  position: relative;
  background-color: #fafafa;
  border: 2px solid #dedede;
  border-radius: 0.7rem;
  padding: 1rem;
}
@media (min-width: 400px) {
  .fill {
    padding: 2rem;
  }
}

@media (max-width: 575px) {
  .fill.fill--actions {
    padding-top: 6rem;
  }
}
.fill.fill--actions .fill-actions {
  display: flex;
  position: absolute;
  top: 1rem;
  right: 1.5rem;
  column-gap: 0.5rem;
}
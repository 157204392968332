.app-container {
    overflow-x: hidden;

    .app-container-child {
        background-image: url("../../../images/frise.webp");

        &--overlay {
            background-color: $background-base;
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: $zindex-navigation + 1;
        }

        .app-container-content {
            min-height: $height-app-content;

            &--fixed {
                height: $height-app-content-overlay;
            }

            #sticky-bottom {
                position: fixed;
                height: $height-menu-flash;
                bottom: $height-menu-bottom;
                left: 0;
                right: 0;

                display: flex;
                justify-content: end;
                padding-top: $spacer-medium;
                padding-bottom: $spacer-medium;
            }

            #sticky-bottom-placeholder {
                display: block;
                content: "";
                height: $height-menu-flash;
            }
        }

        #menu-app {
            // TODO : Sticky bug remove
            &:after {
                display: block;
                content: "";
                height: $height-menu-bottom;
            }
        }
    }
}

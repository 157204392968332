.btn--homepage-search {
    @include font-size(h5);
    margin-top: $spacer-large;
    font-weight: $weight-book;
}

.notification-container {
    position: relative;

    .notification {
        @include font-size(smaller);
        position: absolute;
        top: -0.4em;
        right: -0.4em;
        background-color: $secondary-600;
        height: 1.2em;
        min-width: 1.2em;
        border-radius: 50%;
        color: $egg-shell;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.center-left {
    text-align: center;
}

.fog {
    background: radial-gradient($background-opacity 50%, #ffffff00);

    background-size: $wrapper-large 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.justify {
    text-align: justify;
    text-align-last: left;
}

.overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

strong {
    font-weight: $weight-bold;
}

a.a-disabled {
    color: $color-base;

    &:hover {
        color: $color-base;
    }
}

body[show-status="on"] {
    overflow: hidden;
}

.sticky {
    position: sticky;

    &:not(.sticky--bottom) {
        top: $height-menu-top;
    }

    &.sticky--bottom {
        bottom: $height-menu-bottom;
    }
}

.relative {
    position: relative;
}

@media (max-width: $small - 1) {
    .flex-row.flex-align-middle {
        align-items: center;
        height: 100%;
    }
}
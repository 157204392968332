.faws {
    display: inline-block;

    svg {
        display: block;
        height: 1em;
        width: 1em;

        path {
            fill: currentColor;
        }
    }

    &.hourglass {
        animation: rotateFrame 2s infinite cubic-bezier(.79,.14,.15,.86);
    }

    &.spinner {
        animation: rotateFrame 1s infinite linear;
    }
}

@keyframes rotateFrame {
    0% {
        transform: none;
    }

    100% {
        transform: rotate(360deg);
    }
}

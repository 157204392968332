.wrapper-small {
    max-width: 20rem;
}

.wrapper-small-plus {
    max-width: 35rem;
}

.wrapper-medium {
    max-width: 50rem;
}

.wrapper-medium-plus {
    max-width: $wrapper-medium-plus;
}

%wrapper-large {
    margin-left: auto;
    margin-right: auto;
    max-width: $wrapper-large;

    &--all-margin {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    &--margin, &--all-margin {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.wrapper-large, #breadcrumb > ol, #sticky-bottom {
    @extend %wrapper-large;
}

.block .sub-block, .wrapper-large--margin, #sticky-bottom {
    @extend %wrapper-large;
    @extend %wrapper-large--margin;
}

.wrap-it-all {
    @extend %wrapper-large;
    @extend %wrapper-large--all-margin;
}

.margin-auto {
    margin-right: auto;
    margin-left: auto;
}


.fill {
    position: relative;
    background-color: $white;
    border: $border-2;
    border-radius: $border-radius-2;

    padding: $spacer-small;

    @media (min-width: $tiny) {
        padding: $spacer-medium;
    }
}

.fill.fill--actions {
    @media (max-width: $small - 1) {
        padding-top: $spacer-large-plus;
    }

    .fill-actions {
        display: flex;
        position: absolute;
        top: $spacer-small;
        right: $spacer-small-plus;
        column-gap: $spacer-tiny;
    }
}
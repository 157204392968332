.row-action {
    display: flex;
    flex-wrap: wrap;
    gap: $spacer-small;
    justify-content: end;
    align-items: center;

    &--group {
        flex-wrap: nowrap;
    }
}
.page-header--container {
    margin-top: $spacer-medium;
}

.page-header {
    border: $border-3;
    border-radius: $border-radius-1;
    padding: $spacer-small;

    background: $white;
    display: grid;

    .page-header-image {
        margin: auto;
        height: 100px;
        width: 100px;

        .image-scale {
            border-radius: $border-radius-2;
            box-shadow: $shadow-1;
        }
    }

    .page-header-title {
        text-align: center;
        margin-top: $spacer-medium-plus;
        @include font-size(h3);
    }

    .page-header-subtitle {
        text-align: center;
        margin-top: $spacer-small;
        @include font-size(h4);
    }

    .page-header-short-desc {
        margin-top: $spacer-small;
        text-align: center;

        @media (min-width: $tiny) {
            margin-top: $spacer-small-plus;
        }
    }

    .page-header-address {
        @include font-size(smaller);
        text-align: center;
    }

    .page-header-description {
        @include font-size(smaller);
        max-width: $wrapper-medium-plus;
        margin: $spacer-large auto 0;
        text-align: justify;
        text-align-last: center;

        @media (max-width: $tiny - 1) {
            margin: $spacer-medium auto;
        }
    }

    .page-header-website {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        margin-top: $spacer-small-plus;
    }

    .page-header-action {
        margin-top: $spacer-small-plus;

        &.row-action--group {
            align-items: end;
        }

        > [vue="dropdown"] {
            flex-shrink: 0;
        }
    }
}

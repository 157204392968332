/* Headings */

%title {
    font-family: $font-family-heading;
    margin: 0;
    font-weight: 720;
    letter-spacing: 0.03em;

    &.light {
        font-weight: $weight-light;
    }
}

h1, h2, h3, h4, h5, h6, .h1-like, .h2-like, .h3-like, .h4-like, .h5-like, .h6-like {
    @extend %title;
}

h1, .h1-like {
    @include font-size(h1);

    &.title-space {
        letter-spacing: 0.2em;
    }
}

h2, .h2-like {
    @include font-size(h2);

    &.title-space {
        letter-spacing: 0.15em;
    }
}

h3, .h3-like {
    @include font-size(h3);

    &.title-space {
        letter-spacing: 0.1em;
    }
}

h4, .h4-like {
    @include font-size(h4);
}

h5, .h5-like {
    @include font-size(h5);
}

h6, .h6-like {
    @include font-size(h6);
}

.title-big {
    font-weight: $weight-light;
    text-align: center;

    .title {
        @extend %title;
        @include font-size(h2);
        letter-spacing: 0.5rem;
    }

    .title-sub {
        @extend %title;
        @include font-size(h5);
        margin-top: $spacer-small;
    }
}

//.h1-main {
//    position: relative;
//    overflow: visible clip;
//    color: white;
//    padding: 3rem 0 4rem;
//    display: inline-block;
//    z-index: 1;
//
//    @media (max-width: $extra-large - 1) {
//        margin-left: $spacer-medium;
//    }
//
//    h1 {
//        @include font-size(h3);
//        color: $black;
//    }
//
//    .h1-main-bg {
//        box-shadow: 2px 3px 3px hsla(4, 10%, 5%, 0.5);
//        transform: rotateZ(-3deg) translateY(-2rem);
//        background: $primary-700;
//        position: absolute;
//        top: 0;
//        bottom: 0;
//        left: -5rem;
//        right: -5rem;
//        z-index: -1;
//    }
//}

#breadcrumb {
    @include font-size(smaller);
    border-bottom: 1px solid $gray-000;
    background-color: $egg-shell;
    color: $link-color;
    line-height: 2em;

    ol {
        list-style-type: none;
        display: flex;
        column-gap: $spacer-tiny;
        margin: 0;
        padding: 0 $spacer-small;

        > li {
            &.no-shrink {
                flex-shrink: 0;
            }

            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
}

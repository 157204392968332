// Config file and project variables

// ----------------
// Breakpoints zone
// ----------------

// Warning: you should use your own values, regardless of the devices
// Best practise is Mobile First: (min-width: $breakpoint)
$tiny        : 400px !default; // or 'em' if you prefer, of course
$small       : 576px !default;
$medium      : 768px !default;
$large       : 992px !default;
$extra-large : 1200px !default;

// Wrapper
$wrapper-medium-plus: 650px;
$wrapper-large: 1000px;

// ----------
// Fonts zone
// ----------

// Font families
$font-family-heading   : Karla, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif !default;
$font-family-base      : Mulish, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif !default;
$font-family-header    : $font-family-heading;
$font-family-monospace : consolas, courier, monospace !default; // font for code and samples

// Font sizes (1.6rem value is "16px" equivalent)
$font-size-base : 1.8rem !default;
$font-size-base-mobile : 1.6rem !default;

$ratio-smaller: 0.9;
$ratio-bigger: 1.1;

$ratio-h1: 2.0;
$ratio-h2: 1.8;
$ratio-h3: 1.6;
$ratio-h4: 1.4;
$ratio-h5: 1.3;
$ratio-h6: 1.2;

$ratio-mobile-h1: 1.9;
$ratio-mobile-h2: 1.6;
$ratio-mobile-h3: 1.4;
$ratio-mobile-h4: 1.3;
$ratio-mobile-h5: 1.2;
$ratio-mobile-h6: 1.1;

$font-sizes: (
  smaller: (
    mobile  : $font-size-base-mobile * $ratio-smaller,
    desktop : $font-size-base * $ratio-smaller
  ),
  bigger: (
    mobile  : $font-size-base-mobile * $ratio-bigger,
    desktop : $font-size-base * $ratio-bigger
  ),
  base: (
    mobile  : $font-size-base-mobile,
    desktop : $font-size-base
  ),
  big: (
    mobile  : 3.2rem,
    desktop : 4.275rem
  ),
  h1: (
    mobile  : $font-size-base-mobile * $ratio-mobile-h1,
    desktop : $font-size-base * $ratio-h1,
    ratio   : $ratio-h1,
  ),
  h2: (
    mobile  : $font-size-base-mobile * $ratio-mobile-h2,
    desktop : $font-size-base * $ratio-h2,
    ratio   : $ratio-h2,
  ),
  h3: (
    mobile  : $font-size-base-mobile * $ratio-mobile-h3,
    desktop : $font-size-base * $ratio-h3,
    ratio   : $ratio-h3,
  ),
  h4: (
    mobile  : $font-size-base-mobile * $ratio-mobile-h4,
    desktop : $font-size-base * $ratio-h4,
    ratio   : $ratio-h4,
  ),
  h5: (
    mobile  : $font-size-base-mobile * $ratio-mobile-h5,
    desktop : $font-size-base * $ratio-h5,
    ratio   : $ratio-h5,
  ),
  h6: (
    mobile  : $font-size-base-mobile * $ratio-mobile-h6,
    desktop : $font-size-base * $ratio-h6,
    ratio   : $ratio-h6,
  )
) !default;

$font-awesome: (
  smaller: (
    mobile  : 1.2rem,
    desktop : 1.4rem
  ),
  base: (
    mobile  : 1.4rem,
    desktop : 1.8rem
  ),
  h1: (
    mobile  : 2.8rem,
    desktop : 3.2rem
  ),
  h2: (
    mobile  : 2.4rem,
    desktop : 2.8rem
  ),
  h3: (
    mobile  : 2.0rem,
    desktop : 2.4rem
  ),
  h4: (
    mobile  : 1.8rem,
    desktop : 2.0rem
  ),
  h5: (
    mobile  : 1.6rem,
    desktop : 1.8rem
  ),
  h6: (
    mobile  : 1.4rem,
    desktop : 1.6rem
  )
) !default;

// Line heights
$line-height-s    : 1.3 !default;
$line-height-base : 1.5 !default;
$line-height-l    : 1.7 !default;

// Default margin-bottom
$margin-bottom-base     : 1rem !default;
$paragraph-margin-bottom: $margin-bottom-base !default;

// Font weights
$weight-light   : 200 !default;
$weight-book    : 300 !default;
$weight-regular : 400 !default;
$weight-medium  : 500 !default;
$weight-bold    : 600 !default;

// Activate hyphenation on small screens
$hyphens: false !default;

// ------------
// Spacing zone
// ------------

// Grid gutters (for .has-gutter-* classes)
$grid-gutters: (
  '': 1rem,
  '-l': 2rem,
  '-xl': 4rem
) !default;

// Spacings
$spacer-tiny             : .5rem  !default;
$spacer-tiny-plus        : .7rem !default;
$spacer-small            : 1rem !default;
$spacer-small-plus       : 1.5rem !default;
$spacer-medium           : 2rem !default;
$spacer-medium-plus      : 3rem !default;
$spacer-large            : 4rem !default;
$spacer-large-plus       : 6rem !default;
$spacer-extra-large      : 8rem !default;
$spacer-extra-large-plus : 12rem !default;
$spacer-ultra-large      : 16rem !default;
$spacer-ultra-large-plus : 20rem !default;

// z-indexes
$zindex-navigation   : 1000 !default;
$zindex-slider       : 1500 !default;
$zindex-dropdown     : 2000 !default;
$zindex-popover      : 3000 !default;
$zindex-tooltip      : 4000 !default;
$zindex-modal        : 5000 !default;
$zindex-loading      : 6000 !default;
$zindex-notification : 7000 !default;
$zindex-debug        : 8000 !default;

// ----------
// Color zone
// ----------

// https://hextoral.com/hex-to-pantone-conversion/

// Color names
$primary-900: hsl(199, 100%, 5%);
$primary-800: hsl(199, 90%, 12%); // Pentone 303 C (#003045)
$primary-700: hsl(199, 72%, 24%);
$primary-600: hsl(199, 69%, 31%);
$primary-500: hsl(199, 69%, 37%); // Uni
$primary-400: hsl(199, 62%, 44%);
$primary-300: hsl(199, 64%, 59%); // pastel
$primary-200: hsl(199, 66%, 71%); // pastel
$primary-100: hsl(199, 70%, 83%); // pastel
$primary-050: hsl(199, 35%, 88%); // fill small
$primary-000: hsl(199, 20%, 93%); // fill large

$secondary-900: hsl(-11, 100%, 24%);
$secondary-800: hsl(-7, 100%, 32%);
$secondary-700: hsl(357, 100%, 40%);
$secondary-600: hsl(2, 78%, 51%);
$secondary-500: hsl(4, 90%, 59%); // Uni
$secondary-400: hsl(7, 100%, 65%); // pastel
$secondary-300: hsl(12, 100%, 68%);
$secondary-200: hsl(20, 100%, 71%); // pastel light
$secondary-100: hsl(30, 100%, 74%);
$secondary-050: hsl(30, 35%, 88%); // fill small
$secondary-000: hsl(30, 20%, 93%); // fill large

$orange-900: hsl(23, 100%, 23%);
$orange-600: hsl(23, 100%, 45%);
$orange-500: hsl(30, 100%, 50%); // Uni
$orange-400: hsl(30, 100%, 64%); // pastel
$orange-300: hsl(30, 100%, 70%);
$orange-000: hsl(20, 80%, 93%);

$search-700: hsl(34, 100%, 47%);
$search-600: hsl(41, 100%, 49%); // Uni
$search-500: hsl(46, 100%, 50%); // Pentone 810 C (#FFC70E)
$search-300: hsl(50, 100%, 63%); // pastel light


$green-900: hsl(125, 100%, 20%);
$green-800: hsl(125, 72%, 25%);
$green-700: hsl(125, 62%, 33%);
$green-600: hsl(125, 53%, 45%); // Uni
$green-500: hsl(125, 48%, 55%);
$green-400: hsl(125, 51%, 63%); // pastel
$green-300: hsl(125, 62%, 70%); // pastel
$green-200: hsl(125, 72%, 76%); // pastel
$green-100: hsl(125, 75%, 83%);
$green-000: hsl(125, 20%, 92%);

$gray-900: hsl(4, 5%, 5%); // Pentone 426 C (#191C1F)
$gray-800: hsl(4, 5%, 19%);
$gray-700: hsl(4, 5%, 28%);
$gray-600: hsl(4, 5%, 38%);
$gray-500: hsl(5, 5%, 50%);
$gray-400: hsl(7, 5%, 63%);
$gray-300: hsl(4, 5%, 74%);
$gray-200: hsl(4, 5%, 81%);
$gray-100: hsl(4, 5%, 85%);
$gray-000: hsl(4, 5%, 90%);

$white-flash  : #ffffff !default;
$white        : #fafafa !default;
$egg-shell    : #f8f6f6 !default;
$black        : $gray-900 !default;

// Semantic colors
$background-base : #f3f2f2;
$background-opacity: #f3f2f266; // HEX to add FF opacity
$color-base      : $gray-700;
$color-base-muted: $gray-400;

$overlay-container  : hsla(199, 20%, 93%, 0.8);
$overlay-background : #fff;

$form-color   : $gray-300;
$form-height  : 4rem;

$color-primary   : $primary-500;
$color-highlight : $secondary-700;
$color-success   : $green-500;
$color-warning   : $orange-400;
$color-danger    : $secondary-800;
$color-muted     : $gray-000;
$color-border    : #dedede;

$color-highlight-background : $secondary-600;
$color-highlight-inverse : #fff;

// Others

$inset: 0 2px 0 hsla(0, 0%, 100%, 0.15), inset 0 2px 2px hsla(0, 0%, 0%, 0.1);
$padding-field: $spacer-tiny $spacer-small;

// Border

$border-width-1: 1px;
$border-width-2: 2px;
$border-width-3: 3px;

// ---------------
// Components zone
// ---------------

// Component: links
$link-color            : $color-primary;
$link-color-hover      : $primary-400;
$link-decoration       : underline;
$link-decoration-hover : none;

// Global border-radius
$border-radius-1: 0.3rem !default;
$border-radius-2: 0.7rem !default;
$border-radius-3: 1.5rem !default;

// Component: quotes
$quote-color     : $gray-200;

// Component: arrows
$arrow-color     : $black;

// Components: checkboxes, radios, switches
$checkbox-color         : $gray-400;
$checkbox-size          : 2rem;
$checkbox-border-radius : $border-radius-1;
$switch-color           : $gray-400;
$switch-size            : 2rem;
$switch-border-radius   : 3em;

// Component: tables
$table-border               : $form-color;
$table-caption-color        : $gray-800;
$table-background           : transparent;
$table-head-color           : $color-base;
$table-head-background      : transparent;
$table-footer-color         : $color-base;
$table-footer-background    : transparent;

// Components: buttons, badges, alerts color variants list
// Convention is: name - color - background color - background hover - background ghost - color white - color inverse - border lighter

$variants-list: (
  (primary,   $primary-500,       $primary-600,     $primary-700,     $primary-050,     $white,         $primary-300,   $primary-300,   )
  (highlight, $secondary-700,     $secondary-600,   $secondary-700,   $secondary-050,   $white-flash,   none,           $secondary-200, )
  (search,    $search-600,        $search-500,      $search-600,      $search-300,      $gray-700,      none,           $search-300,    )
  (edit,      $primary-600,       $primary-100,     $primary-200,     $primary-050,     $primary-600,   none,           $primary-100,   )
  (warning,   $orange-600,        $orange-500,      $orange-600,      $orange-300,      $white,         none,           $orange-300,    )
  (danger,    $secondary-900,     $secondary-800,   $secondary-900,   $secondary-050,   $white,         none,           $secondary-600, )
  (success,   $green-900,         $green-700,       $green-600,       $green-100,       $white-flash,   $green-500,     $green-500,     )
) !default;

// Name - border - background - text - icon
$variants-list-alert: (
  (primary,   $primary-300,     $primary-000,     $primary-700, $primary-800),
  (warning,   $orange-500,      $orange-000,      $orange-600,  $orange-600),
  (success,   $green-500,       $green-000,       $green-700,   $green-600),
) !default;

// Name - border - background - text - icon
$variants-list-info: (
  (primary,   $primary-300,     $primary-000,     $primary-700, $primary-800),
) !default;

// Component: tabs
$tabs-border            : $gray-200;
$tabs-active-border     : $gray-800;
$tabs-color             : $color-base;
$tabs-active-color      : $gray-800;
$tabs-background        : transparent;
$tabs-active-background : transparent;
$tabs-border-radius     : 0;

// Component: nav burger button
$burger-color             : $gray-800;
$burger-background        : transparent;
$burger-hover-background  : transparent;
$burger-size              : 2.6rem;
$burger-weight            : 5px; // size of stripes
$burger-padding           : 0;


// Borders
$border-1: $border-width-1 solid $color-border;
$border-2: $border-width-2 solid $color-border;
$border-3: $border-width-3 solid $color-border;

// Shadows
$shadow-1: 0 1px 3px hsla(4, 10%, 5%, .12), 0 1px 3px hsla(4, 10%, 5%, .24);
$shadow-2: 0 3px 6px hsla(4, 10%, 5%, .15), 0 2px 4px hsla(4, 10%, 5%, .12);
$shadow-3: 0 10px 20px hsla(4, 10%, 5%, .15), 0 3px 6px hsla(4, 10%, 5%, .10);
$shadow-4: 0 15px 25px hsla(4, 10%, 5%, .15), 0 5px 10px hsla(4, 10%, 5%, .05);
$shadow-5: 0 20px 40px hsla(4, 10%, 5%, .2);

// Menus
$height-menu-bottom: calc(6rem + 2px);
$height-menu-top: 55px;
$height-menu-top-medium: 45px;
$height-app-content: calc(100vh - (55px + 6rem + 2px));
$height-app-content-top-only: calc(100vh - 45px);
$height-app-content-overlay: calc(100vh - (6rem + 2px));
$height-menu-flash: 11rem; // Height 7rem + 2x2rem padding

// Other colors
$color-facebook  : hsl(221, 44%, 41%);
$color-facebook-light  : hsl(221, 44%, 51%);
$color-google    : hsl(7, 71%, 55%);
$color-google-light    : hsl(7, 71%, 65%);
$color-reddit  : #ff1a00;
$color-twitter  : #2daae1;
$color-linkedin  : #0073b2;

// Standalone or not standalone
$media-not-standalone: "all and (display-mode: fullscreen) and (min-width: #{$small}), all and (display-mode: minimal-ui) and (min-width: #{$small}), all and (display-mode: browser) and (min-width: #{$small})";
$media-standalone: "all and (display-mode: standalone), (max-width: #{$small - 1})";
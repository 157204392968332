body.menu-open{
    overflow: hidden;
}

.search .multiselect--container {
    height: $form-height;
    overflow: visible;
}

[menu-slide-container] {
    // For absolute loading
    position: relative;

    // Hidden when not loaded
    &[menu-slide-state="hidden"] {
        display: none;
    }
}

[menu-slide] {
    &[menu-slide-open="true"] {
        font-family: $font-family-header;

        > [menu-slide-background] {
            pointer-events: auto;
            opacity: 1;
        }

        .multiselect {
            &.is-active {
                &::after {
                    content: "";
                    position: fixed;
                    z-index: -1;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
            }
        }
    }

    > [menu-slide-background] {
        background-color: $overlay-container;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        pointer-events: none;
        position: fixed;
        z-index: $zindex-slider;
        transition-property: opacity;
        transition: ease 250ms;
    }

    > [menu-slide-content] {
        background-color: $overlay-background;
        top: 0;
        bottom: 0;
        height: 100%;
        max-width: 80%;
        width: 20em;
        overflow-x: hidden;
        overflow-y: auto;
        position: fixed;
        transition-property: left, top, right, bottom;
        transition: ease 250ms;
        z-index: $zindex-slider + 1;
    }

    &[menu-slide-position="left"] {
        > [menu-slide-content] {
            left: 0;
        }

        &[menu-slide-open="false"] {
            [menu-slide-content] {
                left: -100%;
            }
        }
    }

    &[menu-slide-position="right"] {
        > [menu-slide-content] {
            right: 0;
        }

        &[menu-slide-open="false"] {
            [menu-slide-content] {
                right: -100%;
            }
        }
    }
}

[menu-shrink] {
    display: flex;

    &[menu-shrink="menu"] {
        text-align: center;
    }

    .menu-shrink--button {
        width: 5rem;
    }

    &[menu-shrink-open="false"] {
        [menu-shrink-show-if="open"] {
            display: none;
        }
    }

    [menu-shrink-content] {
        padding: $spacer-small $spacer-medium $spacer-small $spacer-medium;
        flex-grow: 1;

        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: $spacer-medium;
    }

    [menu-shrink-item] {
        display: flex;
        flex-direction: row;

        > a {
            flex-grow: 1;
        }

        > ul {
            margin: 0;
        }
    }

    [menu-shrink-item="current"] {
        > a {
            pointer-events: none;
            text-decoration: none;
        }
    }

    [menu-shrink-item="item"], [menu-shrink-item="separator"], [menu-shrink-item="ul"] ul > ul {
        display: none;
    }
}

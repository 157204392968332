$show-index: (
    ("default", ("on", "off", "place", "location", "none"))
    ("closing_doors", ("0", "1", "2"))
    ("show_pricing", ("0", "1", "2"))
    ("price_option", ("name", "age", "off"))
    ("price_age", ("min", "max", "off"))
    ("menu-plus", ("on", "off"))
    ("place_category", ("on", "off"))
    ("upload", ("on", "off"))
    ("image", ("on", "off"))
    ("read-more", ("on", "off"))
    ("form_disabled", ("on", "off"))
);

@each $index, $statusArray in $show-index {
    @each $status in $statusArray {
        [show-index="#{$index}"][show-status="#{$status}"] {
            [show-if][show-index="#{$index}"]:not([show-if="#{$status}"]) {
                display: none;

                &.form-row + .form-row {
                    margin-top: 0;
                }
            }
        }
    }
}

[show-auto] {
    cursor: pointer;
}
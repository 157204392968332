.event-result--events-title {
    display: flex;
    align-items: center;
    margin-bottom: $spacer-large;
    flex-wrap: wrap;
    gap: $spacer-medium;

    > .row-action {
        flex-grow: 1;
    }
}

// When maps only and no maps
//@media (max-width: $small - 1) {
//    .event-search--page-header {
//        &.event-search--page-header--maps-only {
//            margin-top: 0;
//        }
//    }
//
//    .block {
//        &.block--h1-main {
//            &.block--event-results--maps-only {
//                margin-top: $spacer-medium;
//
//                h1 {
//                    margin-bottom: $spacer-small;
//                }
//            }
//        }
//    }
//}

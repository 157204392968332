.event-tags {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    column-gap: $spacer-small;
    row-gap: $spacer-tiny;

    > .event-tags--muted {
        color: $color-base-muted;
    }
}

.event-result-container {
    display: flex;
    flex-direction: column;
    row-gap: $spacer-medium;
}

.event-result {
    background-color: $white-flash;
    border: 2px $white solid;
    border-radius: $border-radius-2;
    box-shadow: $shadow-2;
    display: block;
    position: relative;
    overflow: hidden;
    padding: $spacer-medium $spacer-medium $spacer-large-plus;
    text-decoration: none;

    .event-result-actions {
        position: absolute;
        display: flex;
        top: 0;
        right: 0;
    }

    .event-result-flex {
        display: flex;
        align-items: center;
        column-gap: $spacer-medium;
    }

    .event-bottom {
        @include font-size(smaller);

        position: absolute;
        bottom: $spacer-small-plus;
        right: $spacer-medium;

        > .event-tags {
            justify-content: flex-end;
        }
    }
}

.event-result {
    &.event-result-small {
        padding: $spacer-medium;
        height: calc(100px + $spacer-medium * 2 + 4px);

        .event-bottom {
            bottom: $spacer-tiny;
            right: $spacer-small;
            height: 1.5em;
            overflow: hidden;
        }
    }
}

.event-info-container {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: $spacer-medium;

    margin-top: $spacer-medium;
    align-items: center;

    .event-address {
        margin-top: $spacer-medium;
        display: flex;
    }

    .event-info {
        @include font-size(smaller);

        display: flex;
        align-items: center;

        .faws {
            text-align: center;
            width: 1.1em;
            margin-right: $spacer-medium;
        }
    }
}

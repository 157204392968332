.route-list {
    @include font-size(smaller);

    margin-top: $spacer-medium;

    [menu-shrink] {
        box-shadow: none;
        border: $border-1;

        [menu-shrink-item] > ul {
            padding-left: 0;

            > li {
                list-style-type: none;
                font-weight: 500;
            }

            > ul {
                margin-top: $spacer-tiny;

                > li {
                    list-style-type: initial;
                }
            }
        }
    }
}
